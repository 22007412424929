import fs from 'file-saver'
import XLSX from 'xlsx'
//  json: [//列表数据
// {
//   sex: "未知",
//   name: "王二",
//   age: "15",
// }
// ],
// fields: { //头部标题
//   name: "名字",
//   sex: "性别",
//   age: "年龄",
// },
export default (json, fields, filename = '测试数据.xlsx') => { //fields 表头
    json.forEach(item => {
        for (let i in item) {
            if (Object.prototype.hasOwnProperty.call(fields, i)) {
                item[fields[i]] = item[i];
            }
            delete item[i]; //删除原先的对象属性
        }
    })
    let sheetName = filename //excel的文件名称
    let wb = XLSX.utils.book_new()
    let ws = XLSX.utils.json_to_sheet(json, {
        header: Object.values(fields)
    }) //将JS对象数组转换为工作表。
    wb.SheetNames.push(sheetName)
    wb.Sheets[sheetName] = ws
    const defaultCellStyle = {
        font: {
            name: "Verdana",
            sz: 13,
            color: "FF00FF88"
        },
        fill: {
            fgColor: {
                rgb: "FFFFAA00"
            }
        }
    }; //设置表格的样式
    let wopts = {
        bookType: 'xlsx',
        bookSST: false,
        type: 'binary',
        cellStyles: true,
        defaultCellStyle: defaultCellStyle,
        showGridLines: false
    } //写入的样式
    let wbout = XLSX.write(wb, wopts)
    let blob = new Blob([s2ab(wbout)], {
        type: 'application/octet-stream'
    })
    fs.saveAs(blob, filename + '.xlsx')
}
const s2ab = s => {
    if (typeof ArrayBuffer !== 'undefined') {
        let buf = new ArrayBuffer(s.length)
        let view = new Uint8Array(buf)
        for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
        return buf
    } else {
        let buf = new Array(s.length);
        for (let i = 0; i != s.length; ++i) buf[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }
}